.taxonomy-sidebar {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 20px;

  &-category-modal {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &-tree-content {
    padding: 0 0 10px;

    .ant-tree-treenode {
      align-items: center !important;
    }

    .custom-tree-node {
      .ant-dropdown-trigger {
        //display: none;
      }

      .ant-dropdown-button {
        button:focus,
        button:hover {
          background: none;
        }
      }

      &:hover {
        .ant-dropdown-trigger {
          //display: block;
        }
      }

      .ant-dropdown-open {
        display: block !important;
      }
    }
  }
  &-virtual-tree-wrapper {
    // TODO make a right calculation for this
    min-height: 650px;
    height: 100%;
    .ant-btn-text {
      &.selected,
      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.28);
        padding: 4px;
      }
    }
  }
}

.tree-total-count {
  margin: 10px;
  text-align: center;
  font-style: italic;
}

.root {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}

.flex-name {
  .ant-col-4 {
    display: inline-block;
    flex: none;
    max-width: none;
  }
}

.rules-content-edit-settings {
  .anticon {
    margin: 4px;
  }
}
