.settings-content {
  height: 100%;

  .side-bar-content {
    height: 100%;
    border-right: 2px solid #dddddd;
    background: white !important;
    padding: 0;

    &-title {
      font-size: 20px;
      font-weight: bold;
      padding: 12px 24px;
    }

    .ant-layout-sider-trigger {
      width: 23px !important;
      height: 23px;
      position: absolute;
      top: 24px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: white;
      color: #0f192e;
      border: 1px solid #dfd5d5;
      font-size: 12px;
    }

    .radio-group-container {
      width: 100%;
      display: flex;

      .radio-group-button {
        text-align: center;
        flex: 1;

        &-no-border {
          border: 0;
          outline: 0;
          box-shadow: none;

          &:before {
            display: none !important;
          }
        }
      }
    }

    .categories-tree-add-btn {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &-locale {
    padding: 24px;
    height: 100%;
    overflow: auto;

    &-add-btn {
      font-size: 14px !important;
      font-weight: bold !important;
    }

    &-save-btn {
      margin-top: 24px;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
    }

    &-form {
      margin-top: 24px !important;
      display: flex;
      align-items: flex-end;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 12px;
      }
    }
  }

  &-rule {
    padding: 24px;
    height: 100%;
    overflow: auto;
  }

  button {
    -webkit-appearance: button;
    margin: 0 0 0 4px;
  }
}

.client-add-modal {
  .ant-form-item-label {
    width: 30%;
  }
}

.custom-entity-edit-modal {
  &-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    font-weight: bold;
  }
}

.ant-form-item-label {
  min-width: 10%;
}
