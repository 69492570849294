.properties-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.properties-container.n1 {
  grid-template-columns: repeat(1, 1fr);
}

.properties-container.n2 {
  grid-template-columns: repeat(2, 1fr);
}

.properties-container.n3 {
  grid-template-columns: repeat(3, 1fr);
}

.properties-container-title {
  max-height: 1.4em;
  overflow: hidden;
}
