.entity-content {
  padding: 24px;
  height: 100%;
  position: relative;
  overflow: hidden auto;

  .ant-menu {
    background-color: transparent;
    flex: 1;
  }

  .entities-header-panel {
    align-items: center;
    margin-bottom: 1px;

    position: absolute;
    width: 100%;

    h1 {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }

  .side-bar-content {
    border-right: 2px solid #dddddd;
    background: white !important;
    padding: 0;

    .ant-layout-sider-trigger {
      width: 23px !important;
      height: 23px;
      position: absolute;
      top: 24px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: white;
      color: #0f192e;
      border: 1px solid #dfd5d5;
      font-size: 12px;
    }

    .radio-group-container {
      width: 100%;
      display: flex;

      .radio-group-button {
        text-align: center;
        flex: 1;

        &-no-border {
          border: 0;
          outline: 0;
          box-shadow: none;

          &:before {
            display: none !important;
          }
        }
      }
    }

    .categories-tree-add-btn {
      margin: 12px;
    }
  }

  .taxonomy-tabs {
    height: 100%;

    .ant-tabs {
      height: 100%;

      &-tab {
        padding-top: 24px;
      }

      &-content-holder {
        position: relative;
        overflow: auto;
      }

      &-nav {
        padding: 0 12px;
        margin-bottom: 0;
        background: white;
        height: 55px;
      }

      &-content {
        height: 100%;
      }
    }
  }

  .properties-heading {
    padding: 0px 30px;
    margin: 0px;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.virtual-tree-wrapper {
  // TODO make a right calculation for this
  min-height: 650px;
  height: 100%;

  .ant-btn-text {
    &.selected,
    &:hover,
    &:active {
      background: rgba(0, 0, 0, 0.28);
      padding: 4px;
    }
  }
}
