.taxonomy-products-content {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 24px;

  &-title {
    padding-bottom: 0;
    font-size: 20px;
    font-weight: bold;
  }

  .spin-wrapper {
    width: 100%;
    height: 100%;
  }
}

.position-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}
