.properties-config {
  .ant-drawer-body {
    padding: 0;
  }

  .manage-properties-toolbar {
    display: flex;
    justify-content: flex-end;
    padding: 12px;

    &-item {
      margin-right: 12px;
    }
  }

  .models-content {
    height: 100%;
    display: flex;

    &-table {
      flex: 1;

      &-wrapper {
        flex: 1;
        background: #f0f2f5;
        overflow: auto;

        .ant-pro-table-toolbar-default-option {
          display: none;
        }
      }
    }

    &-table-tool {
      display: flex;
      justify-content: space-between;

      .models-search-input {
        width: 200px;
      }
    }

    &-add-property {
      min-width: 350px;
      height: 100%;
      border-left: 2px solid #e8e8e8;
      background: white;
      padding: 12px;
      overflow: auto;

      &-title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      &-label {
        font-size: 12px;
        margin-top: 8px;
      }

      &-item {
        padding: 4px;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        box-shadow: 0 0 2px 2px #e8e8e8;
        display: flex;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;

        &-content {
          display: flex;
          flex-direction: column;
        }

        &-label {
          font-size: 14px;
          font-weight: bold;
          margin-left: 18px;
        }

        &-description {
          font-size: 11px;
          margin-left: 18px;
        }
      }
    }
  }
}

.taxonomy-add-property-modal {
  .ant-modal-close-x {
    width: auto;

    .custom-close-x {
      display: flex;

      img {
        margin: 0 12px;
        max-width: 40px;
      }
    }
  }

  .properties-fields-item {
    margin-bottom: 12px;
    .label-container {
      .label {
        font-weight: bold;
      }
    }
  }

  .ant-input-number {
    width: 100%;
    margin-bottom: 18px;

    input {
      margin-bottom: 0;
    }
  }

  .properties-fields-item {
    margin-bottom: 18px;

    input {
      margin-bottom: 0 !important;
    }
  }

  .section-select {
    margin-bottom: 24px;
  }

  .ant-select-show-search {
    margin-bottom: 18px;
  }

  &-radio {
    margin-bottom: 18px !important;
  }

  &-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    font-weight: bold;
  }

  input,
  textarea,
  .ant-switch {
    margin-bottom: 18px;
  }

  &-item {
    display: flex !important;
    margin-left: 0 !important;
    margin-bottom: 12px !important;

    &-checkbox {
      display: flex !important;
      align-items: center;

      &-wrapper {
        display: flex;
        margin-bottom: 18px;
      }
    }

    .ant-checkbox {
      height: fit-content;
    }

    &-title {
      margin-top: -4px;
      font-weight: bold;
    }
  }
}

.taxonomy-property-section-modal {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.properties-content {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .ant-upload-list-picture-card-container {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}

.models-content-add-property-item {
  img {
    max-width: 40px;
  }
}
